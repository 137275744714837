// User listing
export const GET_USER_LIST = "GET_USER_LIST";
export const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS";
export const GET_USER_LIST_ERROR = "GET_USER_LIST_ERROR";

export const GET_EXPORT_USER_LIST = "GET_EXPORT_USER_LIST";
export const GET_EXPORT_USER_LIST_SUCCESS = "GET_EXPORT_USER_LIST_SUCCESS";
export const GET_EXPORT_USER_LIST_ERROR = "GET_EXPORT_USER_LIST_ERROR";

//delete
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";

//edit

export const EDIT_USER = "EDIT_USER";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_ERROR = "EDIT_USER_ERROR";

//setting data for edit
export const SET_USER_LIST = "SET_USER_LIST";

// set search of ingredients
export const SET_USER_SEARCH = "SET_USER_SEARCH";

// set sortField of ingredients
export const SET_USERS_SORT_FIELD = "SET_USERS_SORT_FIELD";

// set sortOrder of ingredients
export const SET_USERS_SORT_ORDER = "SET_USERS_SORT_ORDER";

// set page of ingredients
export const SET_USERS_PAGE = "SET_USERS_PAGE";
