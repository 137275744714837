import { LOGIN_SUCCESS, LOGIN_ERROR, GET_LOGIN } from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
  user: null,
  isAuth: false,
  success: "",
};

const loginReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_LOGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuth: true,
        user: payload,
        loading: false,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default loginReducer;
