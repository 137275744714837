import { getUser } from "helpers/localStorage";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const RequiredAuth = () => {
  // Authentication logic goes here

  const user = getUser();
  const location = useLocation();

  if (!!!user)
    return (
      <Navigate
        to={"/login"}
        state={{
          from: location.pathname,
        }}
        replace
      />
    );

  return <Outlet />;
};

export default RequiredAuth;
