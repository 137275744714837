import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Routes from "routes";
import "index.css";
import "react-toastify/dist/ReactToastify.css";
const App = () => {
  const location = useLocation();

  useEffect(() => {
    document.querySelector(".main_part")?.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <ToastContainer autoClose={3000} />
      <Routes />
    </>
  );
};

export default App;
