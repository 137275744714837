import {
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  GET_RESET_PASSWORD,
  CHECK_RESET_PASSWORD,
  CHECK_RESET_PASSWORD_SUCCESS,
  CHECK_RESET_PASSWORD_ERROR,
} from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
  user: null,
  resetLoading: false,
  checkResetToken: null,
};

const resetPasswordReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_RESET_PASSWORD:
      return {
        ...state,
        loading: true,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        user: payload,
        loading: false,
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
      };
    //check reset password
    case CHECK_RESET_PASSWORD:
      return {
        ...state,
        resetLoading: true,
      };
    case CHECK_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        checkResetToken: payload,
        resetLoading: false,
      };
    case CHECK_RESET_PASSWORD_ERROR:
      return {
        ...state,
        resetLoading: false,
      };
    default:
      return state;
  }
};

export default resetPasswordReducer;
