import {
  ADD_GOAL,
  ADD_RECIPE_CATEGORY,
  DELETE_GOAL,
  DELETE_RECIPE_CATEGORY,
} from "helpers/url_helpers";
import produce from "immer";
import {
  ADD_AISLE_DATA,
  ADD_AISLE_DATA_ERROR,
  ADD_AISLE_DATA_SUCCESS,
  ADD_GOALS_ERROR,
  ADD_GOALS_SUCCESS,
  ADD_RECIPE_CATEGORY_ERROR,
  ADD_RECIPE_CATEGORY_SUCCESS,
  ADD_TAG,
  ADD_TAGS_ERROR,
  ADD_TAGS_SUCCESS,
  DELETE_AISLE_DATA,
  DELETE_AISLE_DATA_ERROR,
  DELETE_AISLE_DATA_SUCCESS,
  DELETE_GOALS_ERROR,
  DELETE_GOALS_SUCCESS,
  DELETE_RECIPE_CATEGORY_ERROR,
  DELETE_RECIPE_CATEGORY_SUCCESS,
  DELETE_TAG,
  DELETE_TAGS_ERROR,
  DELETE_TAGS_SUCCESS,
  EDIT_TAG,
  EDIT_TAGS_ERROR,
  EDIT_TAGS_SUCCESS,
  GET_AISLE_DATA_LIST,
  GET_AISLE_DATA_LIST_SUCCESS,
  GET_GOAL_LIST,
  GET_GOAL_LIST_SUCCESS,
  GET_RECIPE_CATEGORY_LIST,
  GET_RECIPE_CATEGORY_LIST_SUCCESS,
  GET_TAGS_LIST,
  GET_TAGS_LIST_ERROR,
  GET_TAGS_LIST_SUCCESS,
  SET_TAGS_LIST,
  SET_TAGS_PAGE,
  SET_TAGS_SEARCH,
  SET_TAGS_SORT_FIELD,
  SET_TAGS_SORT_ORDER,
} from "./actionTypes";

const initialState = {
  allTagsList: [],
  allAisleList: [],
  allRecipeCategory: [],
  allMealPlan: [],
  loading: false,
  error: "",
  totalCount: 0,
  totalPages: 0,
  limit: 10,
  page: 1,
  sortOrder: "",
  sortField: "",
  search: "",
  addTagLoad: false,
  deleteTagLoad: false,
  editTag: null,
};

const tagsReducer = produce((state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_TAGS_LIST:
      return {
        ...state,
        loading: true,
      };

    case GET_TAGS_LIST_SUCCESS:
      return {
        ...state,
        allTagsList: payload?.data,
        totalCount: payload?.metadata?.totalCount,
        totalPages: payload?.metadata?.totalPages,
        limit: payload?.metadata?.limit,
        count: payload?.metadata?.count,
        loading: false,
      };

    case GET_TAGS_LIST_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    // set tags search
    case SET_TAGS_SEARCH:
      return {
        ...state,
        search: payload,
      };
    // set tags sortField
    case SET_TAGS_SORT_FIELD:
      return {
        ...state,
        sortField: payload,
      };

    // set tags sortOrder
    case SET_TAGS_SORT_ORDER:
      return {
        ...state,
        sortOrder: payload,
      };
    // set tags Page
    case SET_TAGS_PAGE:
      return {
        ...state,
        page: payload,
      };

    //  Get Aisle
    case GET_AISLE_DATA_LIST:
      return {
        ...state,
        loading: true,
      };

    case GET_AISLE_DATA_LIST_SUCCESS:
      return {
        ...state,
        allAisleList: payload?.data,
        totalCount: payload?.metadata?.totalCount,
        totalPages: payload?.metadata?.totalPages,
        limit: payload?.metadata?.limit,
        count: payload?.metadata?.count,
        loading: false,
      };

    // Add Aisle
    case ADD_AISLE_DATA:
      return {
        ...state,
        addTagLoad: true,
      };
    case ADD_AISLE_DATA_SUCCESS:
      return {
        ...state,
        addTagLoad: false,
      };
    case ADD_AISLE_DATA_ERROR:
      return {
        ...state,
        addTagLoad: false,
      };

    //delete Aisle
    case DELETE_AISLE_DATA:
      return {
        ...state,
        deleteTagLoad: true,
      };
    case DELETE_AISLE_DATA_SUCCESS:
      return {
        ...state,
        deleteTagLoad: false,
      };
    case DELETE_AISLE_DATA_ERROR:
      return {
        ...state,
        deleteTagLoad: false,
      };

    //  Get Aisle
    case GET_GOAL_LIST:
      return {
        ...state,
        loading: true,
      };

    case GET_GOAL_LIST_SUCCESS:
      return {
        ...state,
        allMealPlan: payload?.data,
        totalCount: payload?.metadata?.totalCount,
        totalPages: payload?.metadata?.totalPages,
        limit: payload?.metadata?.limit,
        count: payload?.metadata?.count,
        loading: false,
      };

    // Add Aisle
    case ADD_GOAL:
      return {
        ...state,
        addTagLoad: true,
      };
    case ADD_GOALS_SUCCESS:
      return {
        ...state,
        addTagLoad: false,
      };
    case ADD_GOALS_ERROR:
      return {
        ...state,
        addTagLoad: false,
      };

    //delete Aisle
    case DELETE_GOAL:
      return {
        ...state,
        deleteTagLoad: true,
      };
    case DELETE_GOALS_SUCCESS:
      return {
        ...state,
        deleteTagLoad: false,
      };
    case DELETE_GOALS_ERROR:
      return {
        ...state,
        deleteTagLoad: false,
      };

    //  Get Aisle
    case GET_RECIPE_CATEGORY_LIST:
      return {
        ...state,
        loading: true,
      };

    case GET_RECIPE_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        allRecipeCategory: payload?.data,
        totalCount: payload?.metadata?.totalCount,
        totalPages: payload?.metadata?.totalPages,
        limit: payload?.metadata?.limit,
        count: payload?.metadata?.count,
        loading: false,
      };

    // Add Aisle
    case ADD_RECIPE_CATEGORY:
      return {
        ...state,
        addTagLoad: true,
      };
    case ADD_RECIPE_CATEGORY_SUCCESS:
      return {
        ...state,
        addTagLoad: false,
      };
    case ADD_RECIPE_CATEGORY_ERROR:
      return {
        ...state,
        addTagLoad: false,
      };

    //delete Aisle
    case DELETE_RECIPE_CATEGORY:
      return {
        ...state,
        deleteTagLoad: true,
      };
    case DELETE_RECIPE_CATEGORY_SUCCESS:
      return {
        ...state,
        deleteTagLoad: false,
      };
    case DELETE_RECIPE_CATEGORY_ERROR:
      return {
        ...state,
        deleteTagLoad: false,
      };

    case ADD_TAG:
      return {
        ...state,
        addTagLoad: true,
      };
    case ADD_TAGS_SUCCESS:
      return {
        ...state,
        addTagLoad: false,
      };
    case ADD_TAGS_ERROR:
      return {
        ...state,
        addTagLoad: false,
      };
    case EDIT_TAG:
      return {
        ...state,
        loading: true,
      };
    case EDIT_TAGS_SUCCESS:
      return {
        ...state,
        loading: false,
        editTag: payload,
      };
    case EDIT_TAGS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case SET_TAGS_LIST:
      return {
        ...state,
        allTagsList: payload,
      };

    //delete
    case DELETE_TAG:
      return {
        ...state,
        deleteTagLoad: true,
      };
    case DELETE_TAGS_SUCCESS:
      return {
        ...state,
        deleteTagLoad: false,
      };
    case DELETE_TAGS_ERROR:
      return {
        ...state,
        deleteTagLoad: false,
      };
    default:
      return state;
  }
});

export default tagsReducer;
