import { Suspense } from "react";
import {
  Navigate,
  Routes as ReactRouterDomRoutes,
  Route,
} from "react-router-dom";

import Layout from "layout";
import { getUser } from "../helpers/localStorage";
import routesConfig from "./routes.config";

const Common = (route) => (
  <Suspense fallback={""}>
    <route.component />
  </Suspense>
);

const Public = (route) => {
  const user = getUser();

  const redirectTo = "/ingredients";

  if (!!user) return <Navigate to={redirectTo} replace />;

  return (
    <Suspense fallback={""}>
      <route.component />
    </Suspense>
  );
};

const Private = (route) => {
  const { component: Component } = route;
  const user = getUser();
  const redirectTo = "/login";
  if (window.location.pathname === "/")
    return <Navigate to={"/ingredients"} replace />;
  if (!user) return <Navigate to={redirectTo} replace />;

  return (
    <Suspense fallback={""}>
      <Component />
    </Suspense>
  );
};

const createNestedRoutes = (routes, RouteType) => {
  return routes.map((route, i) => {
    if (!route.component) {
      throw new Error("Component must be required....");
    }
    if (route.children) {
      return (
        <Route path={route.path} key={i} element={<RouteType {...route} />}>
          {createNestedRoutes(route.children, RouteType)}
        </Route>
      );
    } else {
      return (
        <Route
          key={i}
          index={route.index}
          path={route.path}
          element={<RouteType {...route} />}
        />
      );
    }
  });
};

const Routes = () => {
  const { common, private: privateRoutes, public: publicRoutes } = routesConfig;
  return (
    <ReactRouterDomRoutes>
      <Route path="/" element={<Layout />}>
        {createNestedRoutes(common, Common)}
        {createNestedRoutes(privateRoutes, Private)}
        {createNestedRoutes(publicRoutes, Public)}
      </Route>
    </ReactRouterDomRoutes>
  );
};

export default Routes;
