// Product listing
export const GET_PRODUCT_LIST = "GET_PRODUCT_LIST";
export const GET_PRODUCT_LIST_SUCCESS = "GET_PRODUCT_LIST_SUCCESS";
export const GET_PRODUCT_LIST_ERROR = "GET_PRODUCT_LIST_ERROR";

//add new
export const ADD_PRODUCT = "ADD_PRODUCT";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_ERROR = "ADD_PRODUCT_ERROR";

//edit
export const EDIT_PRODUCT = "ADD_PRODUCT";
export const EDIT_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const EDIT_PRODUCT_ERROR = "ADD_PRODUCT_ERROR";

//delete
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_ERROR = "DELETE_PRODUCT_ERROR";

// single Data
export const GET_SINGLE_CHALLENGE = "GET_SINGLE_CHALLENGE";
export const GET_SINGLE_CHALLENGE_SUCCESS = "GET_SINGLE_CHALLENGE_SUCCESS";
export const GET_SINGLE_CHALLENGE_ERROR = "GET_SINGLE_CHALLENGE_ERROR";

//setting data for edit
export const SET_PRODUCT_LIST = "SET_PRODUCT_LIST";

// set status of Product
export const SET_PRODUCT_STATUS = "SET_PRODUCT_STATUS";

// set search of Product
export const SET_PRODUCT_SEARCH = "SET_PRODUCT_SEARCH";

// set sortField of Product
export const SET_PRODUCTS_SORT_FIELD = "SET_PRODUCTS_SORT_FIELD";

// set sortOrder of Product
export const SET_PRODUCTS_SORT_ORDER = "SET_PRODUCTS_SORT_ORDER";

// set sizePerPage
export const SET_PRODUCTS_SIZE_PER_PAGE = "SET_PRODUCTS_SIZE_PER_PAGE";

// set page of Product
export const SET_PRODUCTS_PAGE = "SET_PRODUCTS_PAGE";

export const DRAG_AND_DROP_PRODUCT = "DRAG_AND_DROP_PRODUCT";
