import Logo from "../assets/images/logo.png";
import Logout from "../assets/images/logout.png";
import AvtarImg from "../assets/images/avatar-2.png";
import LocalStorage from "helpers/localStorage";
import { Link, useNavigate } from "react-router-dom";
import { getUser } from "../helpers/localStorage";
const Header = ({ location }) => {
  const user = getUser();

  const navigate = useNavigate();
  //const { user } = useSelector((state) => state.Login);
  const urlName = location?.slice(1).toUpperCase();
  const pageTitle = urlName?.replace(/-/g, " ");
  const handleLogout = () => {
    LocalStorage.clean();
    navigate("/login");
  };

  return (
    <header>
      <div className="main_logo">
        <Link to="ingredients">
          <img src={Logo} alt="Logo" />
        </Link>
      </div>
      <div className="right_header">
        <h4 className="main_title">
          {pageTitle === "NEW RECIPES"
            ? "NEW RECIPE"
            : pageTitle === "EDIT RECIPES"
            ? "EDIT RECIPE"
            : pageTitle}
        </h4>
        <div className="user_live">
          {/* <p className="breadcrumb_name flex">STAGING, NOT LIVE</p> */}
          <div className="user_content">
            <div className="user_image">
              <img src={AvtarImg} alt="profile" className="user_image_url" />
            </div>
            <div className="dropdown user_dropdown">
              <button
                type="button"
                className="btn dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                {user?.name}
              </button>
              <ul className="dropdown-menu">
                <li>
                  <button className="dropdown-item" onClick={handleLogout}>
                    <img src={Logout} alt="logout_icon" /> Logout
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
