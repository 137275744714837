import produce from "immer";
import {
  GET_USER_LIST,
  GET_USER_LIST_SUCCESS,
  GET_USER_LIST_ERROR,
  GET_EXPORT_USER_LIST,
  GET_EXPORT_USER_LIST_SUCCESS,
  GET_EXPORT_USER_LIST_ERROR,
  SET_USER_LIST,
  SET_USER_SEARCH,
  SET_USERS_SORT_FIELD,
  SET_USERS_SORT_ORDER,
  SET_USERS_PAGE,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  EDIT_USER,
  EDIT_USER_ERROR,
  EDIT_USER_SUCCESS,
} from "./actionTypes";

const initialState = {
  allUsersList: null,
  total_accounts: 0,
  total_subscibers: 0,
  loading: false,
  error: "",
  totalCount: 0,
  totalPages: 0,
  limit: 10,
  page: 1,
  sortOrder: "",
  sortField: "",
  search: "",
  allExportUsersList: null,
  loadingExport: false,
  deleteUserLoad: false,
  editUserLoad: false,
};

const usersReducer = produce((state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_USER_LIST:
      return {
        ...state,
        loading: true,
      };

    //set user list
    case GET_USER_LIST_SUCCESS:
      return {
        ...state,
        allUsersList: payload.data[0]?.userDetails,
        total_accounts: payload.data[0].total_accounts,
        total_subscibers: payload.data[0].total_subscibers,
        totalCount: payload.metadata.totalCount,
        totalPages: payload.metadata.totalPages,
        // page: payload.metadata.pages,
        limit: payload.metadata.limit,
        count: payload.metadata.count,
        loading: false,
      };
    case GET_USER_LIST_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    //set user export list
    case GET_EXPORT_USER_LIST:
      return {
        ...state,
        loadingExport: true,
      };

    case GET_EXPORT_USER_LIST_SUCCESS:
      return {
        ...state,
        allExportUsersList: payload.data[0].userDetails,
        loadingExport: false,
      };
    case GET_EXPORT_USER_LIST_ERROR:
      return {
        ...state,
        error: payload,
        loadingExport: false,
      };

    //add

    case EDIT_USER:
      return {
        ...state,
        editUserLoad: true,
      };

    case EDIT_USER_SUCCESS:
      return {
        ...state,
        editUserLoad: false,
      };

    case EDIT_USER_ERROR:
      return {
        ...state,
        editUserLoad: false,
      };

    //delete
    case DELETE_USER:
      return {
        ...state,
        deleteUserLoad: true,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        deleteUserLoad: false,
      };
    case DELETE_USER_ERROR:
      return {
        ...state,
        deleteUserLoad: false,
      };

    // set Users search
    case SET_USER_SEARCH:
      return {
        ...state,
        search: payload,
      };
    // set Users sortField
    case SET_USERS_SORT_FIELD:
      return {
        ...state,
        sortField: payload,
      };

    // set Users sortOrder
    case SET_USERS_SORT_ORDER:
      return {
        ...state,
        sortOrder: payload,
      };
    // set Users Page
    case SET_USERS_PAGE:
      return {
        ...state,
        page: payload,
      };

    case SET_USER_LIST:
      return {
        ...state,
        allUsersList: payload,
      };

    default:
      return state;
  }
});

export default usersReducer;
