import produce from "immer";
import {
  ADD_PRODUCT,
  ADD_PRODUCT_ERROR,
  ADD_PRODUCT_SUCCESS,
  EDIT_PRODUCT,
  EDIT_PRODUCT_SUCCESS,
  EDIT_PRODUCT_ERROR,
  DELETE_PRODUCT,
  DELETE_PRODUCT_ERROR,
  DELETE_PRODUCT_SUCCESS,
  GET_PRODUCT_LIST,
  GET_PRODUCT_LIST_ERROR,
  GET_PRODUCT_LIST_SUCCESS,
  SET_PRODUCTS_PAGE,
  SET_PRODUCTS_SORT_FIELD,
  SET_PRODUCTS_SORT_ORDER,
  SET_PRODUCT_LIST,
  SET_PRODUCT_SEARCH,
  GET_SINGLE_CHALLENGE,
  GET_SINGLE_CHALLENGE_SUCCESS,
  GET_SINGLE_CHALLENGE_ERROR,
  DRAG_AND_DROP_PRODUCT,
  SET_PRODUCTS_SIZE_PER_PAGE,
} from "./actionTypes";

const initialState = {
  allProductsList: [],
  product: null,
  loading: false,
  error: "",
  totalPages: 0,
  limit: "",
  page: 1,
  sortOrder: "",
  sortField: "",
  search: "",
  addProductLoad: false,
  deleteProductLoad: false,
};

const challengesReducer = produce((state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case DRAG_AND_DROP_PRODUCT:
      return {
        ...state,
        allProductsList: payload,
      };
    case GET_PRODUCT_LIST:
      return {
        ...state,
        loading: true,
      };

    //set Challenges list
    case GET_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        allProductsList: payload,
        loading: false,
      };
    case GET_PRODUCT_LIST_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    // Add Product
    case ADD_PRODUCT:
      return {
        ...state,
        addProductLoad: true,
      };
    case ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        addProductLoad: false,
      };
    case ADD_PRODUCT_ERROR:
      return {
        ...state,
        addProductLoad: false,
      };

    // Edit Product
    case EDIT_PRODUCT:
      return {
        ...state,
        addProductLoad: true,
      };
    case EDIT_PRODUCT_SUCCESS:
      return {
        ...state,
        addProductLoad: false,
      };
    case EDIT_PRODUCT_ERROR:
      return {
        ...state,
        addProductLoad: false,
      };

    // Single Product
    case GET_SINGLE_CHALLENGE:
      return {
        ...state,
        addProductLoad: true,
      };
    case GET_SINGLE_CHALLENGE_SUCCESS:
      return {
        ...state,
        addProductLoad: false,
        product: payload,
      };
    case GET_SINGLE_CHALLENGE_ERROR:
      return {
        ...state,
        addProductLoad: false,
      };

    // sizePerPage

    case SET_PRODUCTS_SIZE_PER_PAGE:
      return {
        ...state,
        limit: payload,
      };

    // set Challenges search
    case SET_PRODUCT_SEARCH:
      return {
        ...state,
        search: payload,
      };
    // set Challenges sortField
    case SET_PRODUCTS_SORT_FIELD:
      return {
        ...state,
        sortField: payload,
      };

    // set Challenges sortOrder
    case SET_PRODUCTS_SORT_ORDER:
      return {
        ...state,
        sortOrder: payload,
      };
    // set Challenges Page
    case SET_PRODUCTS_PAGE:
      return {
        ...state,
        page: payload,
      };

    case SET_PRODUCT_LIST:
      return {
        ...state,
        allProductsList: payload,
      };

    //delete
    case DELETE_PRODUCT:
      return {
        ...state,
        deleteProductLoad: true,
      };
    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        deleteProductLoad: false,
      };
    case DELETE_PRODUCT_ERROR:
      return {
        ...state,
        deleteProductLoad: false,
      };
    default:
      return state;
  }
});

export default challengesReducer;
