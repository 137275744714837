import { combineReducers } from "redux";

import Login from "modules/login/store/reducer";
import ForgotPassword from "modules/forgotPassword/store/reducer";
import ResetPassword from "modules/resetPassword/store/reducer";
import ingredients from "modules/ingredients/store/reducer";
import challenges from "modules/challenges/store/reducer";
import recipes from "modules/recipes/store/reducer";
import videos from "modules/videos/store/reducer";
import pushNotifications from "modules/pushNotifications/store/reducer";
import users from "modules/users/store/reducer";
import FaqReducer from "modules/faq/store/reducers";
import tags from "modules/category/store/reducer";
import subscriber from "modules/subscribers/store/reducer";
import product from "modules/products/store/reducer";

const rootReducer = combineReducers({
  Login,
  ForgotPassword,
  ResetPassword,
  ingredients,
  challenges,
  recipes,
  videos,
  pushNotifications,
  users,
  FaqReducer,
  tags,
  subscriber,
  product,
});

export default rootReducer;
