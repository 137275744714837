// recipe listing
export const GET_RECIPE_LIST = "GET_RECIPE_LIST";
export const GET_RECIPE_LIST_SUCCESS = "GET_RECIPE_LIST_SUCCESS";
export const GET_RECIPE_LIST_ERROR = "GET_RECIPE_LIST_ERROR";

//single recipe detail
export const GET_RECIPE = "GET_RECIPE";
export const GET_RECIPE_SUCCESS = "GET_RECIPE_SUCCESS";
export const GET_RECIPE_ERROR = "GET_RECIPE_ERROR";

// category listing
export const GET_CATEGORY_LIST = "GET_CATEGORY_LIST";
export const GET_CATEGORY_LIST_SUCCESS = "GET_CATEGORY_LIST_SUCCESS";
export const GET_CATEGORY_LIST_ERROR = "GET_CATEGORY_LIST_ERROR";

//add recipe
export const ADD_RECIPE = "ADD_RECIPE";
export const ADD_RECIPE_SUCCESS = "ADD_RECIPE_SUCCESS";
export const ADD_RECIPE_ERROR = "ADD_RECIPE_ERROR";

//edit recipe
export const EDIT_RECIPE = "EDIT_RECIPE";
export const EDIT_RECIPE_SUCCESS = "EDIT_RECIPE_SUCCESS";
export const EDIT_RECIPE_ERROR = "EDIT_RECIPE_ERROR";

//delete recipe
export const DELETE_RECIPE = "DELETE_RECIPE";
export const DELETE_RECIPE_SUCCESS = "DELETE_RECIPE_SUCCESS";
export const DELETE_RECIPE_ERROR = "DELETE_RECIPE_ERROR";

//Recipe status filter
export const GET_RECIPE_STATUS_LIST = "GET_RECIPE_STATUS_LIST";
export const GET_RECIPE_STATUS_LIST_SUCCESS = "GET_RECIPE_STATUS_LIST_SUCCESS";
export const GET_RECIPE_STATUS_LIST_ERROR = "GET_RECIPE_STATUS_LIST_ERROR";

//Recipe status Update
export const RECIPE_STATUS_UPDATE = "RECIPE_STATUS_UPDATE";
export const RECIPE_STATUS_UPDATE_SUCCESS = "RECIPE_STATUS_UPDATE_SUCCESS";
export const RECIPE_STATUS_UPDATE_ERROR = "RECIPE_STATUS_UPDATE_ERROR";

// Export Recipe Data

export const EXPORT_RECIPE_STATUS_DATA = "EXPORT_RECIPE_STATUS_DATA";
export const EXPORT_RECIPE_STATUS_DATA_SUCCESS =
  "EXPORT_RECIPE_STATUS_DATA_SUCCESS";
export const EXPORT_RECIPE_STATUS_DATA_ERROR =
  "EXPORT_RECIPE_STATUS_DATA_ERROR";

//Recipe ingredient list
export const GET_RECIPES_INGREDIENT_LIST = "GET_RECIPES_INGREDIENT_LIST";
export const GET_RECIPES_INGREDIENT_LIST_SUCCESS =
  "GET_RECIPES_INGREDIENT_LIST_SUCCESS";
export const GET_RECIPES_INGREDIENT_LIST_ERROR =
  "GET_RECIPES_INGREDIENT_LIST_ERROR";

// List Tags

export const GET_TAG_LIST = "GET_TAG_LIST";
export const GET_TAG_LIST_SUCCESS = "GET_TAG_LIST_SUCCESS";
export const GET_TAG_LIST_ERROR = "GET_TAG_LIST_ERROR";

//setting data for edit
export const SET_RECIPE_LIST = "SET_RECIPE_LIST";

// set search of recipes
export const SET_RECIPE_STATUS = "SET_RECIPE_STATUS";

// set search of recipes
export const SET_RECIPE_SEARCH = "SET_RECIPE_SEARCH";

// set sortField of recipes
export const SET_RECIPES_SORT_FIELD = "SET_RECIPES_SORT_FIELD";

// set sortOrder of recipes
export const SET_RECIPES_SORT_ORDER = "SET_RECIPES_SORT_ORDER";

// set page of recipes
export const SET_RECIPES_PAGE = "SET_RECIPES_PAGE";
