// Challenges listing
export const GET_CHALLENGE_LIST = "GET_CHALLENGE_LIST";
export const GET_CHALLENGE_LIST_SUCCESS = "GET_CHALLENGE_LIST_SUCCESS";
export const GET_CHALLENGE_LIST_ERROR = "GET_CHALLENGE_LIST_ERROR";

// Challenges listing
export const GET_SINGLE_CHALLENGE = "GET_SINGLE_CHALLENGE";
export const GET_SINGLE_CHALLENGE_SUCCESS = "GET_SINGLE_CHALLENGE_SUCCESS";
export const GET_SINGLE_CHALLENGE_ERROR = "GET_SINGLE_CHALLENGE_ERROR";

//Challenge status Update
export const CHALLENGE_STATUS_UPDATE = "CHALLENGE_STATUS_UPDATE";
export const CHALLENGE_STATUS_UPDATE_SUCCESS =
  "CHALLENGE_STATUS_UPDATE_SUCCESS";
export const CHALLENGE_STATUS_UPDATE_ERROR = "CHALLENGE_STATUS_UPDATE_ERROR";

//Challenge status Update
export const CHALLENGE_FEATURE_UPDATE = "CHALLENGE_FEATURE_UPDATE";
export const CHALLENGE_FEATURE_UPDATE_SUCCESS =
  "CHALLENGE_FEATURE_UPDATE_SUCCESS";
export const CHALLENGE_FEATURE_UPDATE_ERROR = "CHALLENGE_FEATURE_UPDATE_ERROR";

//getting goal list for dropdown
export const GET_GOAL_LIST = "GET_GOAL_LIST";
export const GET_GOAL_LIST_SUCCESS = "GET_GOAL_LIST_SUCCESS";
export const GET_GOAL_LIST_ERROR = "GET_GOAL_LIST_ERROR";

// aisle listing
export const GET_AISLE_LIST = "GET_AISLE_LIST";
export const GET_AISLE_LIST_SUCCESS = "GET_AISLE_LIST_SUCCESS";
export const GET_AISLE_LIST_ERROR = "GET_AISLE_LIST_ERROR";

//Recipes listing
export const GET_CHALLENGES_RECIPE_LIST = "GET_CHALLENGES_RECIPE_LIST";
export const GET_CHALLENGES_RECIPE_LIST_SUCCESS =
  "GET_CHALLENGES_RECIPE_LIST_SUCCESS";
export const GET_CHALLENGES_RECIPE_LIST_ERROR =
  "GET_CHALLENGES_RECIPE_LIST_ERROR";

//add new
export const ADD_CHALLENGE = "ADD_CHALLENGE";
export const ADD_CHALLENGE_SUCCESS = "ADD_CHALLENGE_SUCCESS";
export const ADD_CHALLENGE_ERROR = "ADD_CHALLENGE_ERROR";

//edit
export const EDIT_CHALLENGE = "ADD_CHALLENGE";
export const EDIT_CHALLENGE_SUCCESS = "ADD_CHALLENGE_SUCCESS";
export const EDIT_CHALLENGE_ERROR = "ADD_CHALLENGE_ERROR";

//delete
export const DELETE_CHALLENGE = "DELETE_CHALLENGE";
export const DELETE_CHALLENGE_SUCCESS = "DELETE_CHALLENGE_SUCCESS";
export const DELETE_CHALLENGE_ERROR = "DELETE_CHALLENGE_ERROR";

//setting data for edit
export const SET_CHALLENGE_LIST = "SET_CHALLENGE_LIST";

// set status of Challenges
export const SET_CHALLENGE_STATUS = "SET_CHALLENGE_STATUS";

// set search of Challenges
export const SET_CHALLENGE_SEARCH = "SET_CHALLENGE_SEARCH";

// set sortField of Challenges
export const SET_CHALLENGES_SORT_FIELD = "SET_CHALLENGES_SORT_FIELD";

// set sortOrder of Challenges
export const SET_CHALLENGES_SORT_ORDER = "SET_CHALLENGES_SORT_ORDER";

// set page of Challenges
export const SET_CHALLENGES_PAGE = "SET_CHALLENGES_PAGE";
