import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import Header from "./Header";
import SideBar from "./SideBar";
import Content from "./Content";
const Layout = () => {
  const [isAuth, setIsAuth] = useState(true);
  const [isSidebar, setIsSidebar] = useState(false);
  const getTempUrl = window.location.pathname;

  useEffect(() => {
    if (
      getTempUrl === "/login" ||
      getTempUrl === "/forgot-password" ||
      getTempUrl === "/reset-password"
    ) {
      setIsAuth(false);
    } else {
      setIsAuth(true);
    }
  }, [getTempUrl]);

  const openSidebar = () => {
    if (isSidebar === true) {
      setIsSidebar(false);
    } else {
      setIsSidebar(true);
    }
  };

  return isAuth ? (
    <>
      <div className="main_wrapper">
        <Header location={getTempUrl} />
        <div className="main_part">
          <SideBar isSidebar={isSidebar} openSidebar={openSidebar} />
          <Content>
            <Outlet />
          </Content>
        </div>
      </div>
    </>
  ) : (
    <Outlet />
  );
};

export default Layout;
