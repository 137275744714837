// ingredients listing
export const GET_INGREDIENT_LIST = "GET_INGREDIENT_LIST";
export const GET_INGREDIENT_LIST_SUCCESS = "GET_INGREDIENT_LIST_SUCCESS";
export const GET_INGREDIENT_LIST_ERROR = "GET_INGREDIENT_LIST_ERROR";

// aisle listing
export const GET_AISLE_LIST = "GET_AISLE_LIST";
export const GET_AISLE_LIST_SUCCESS = "GET_AISLE_LIST_SUCCESS";
export const GET_AISLE_LIST_ERROR = "GET_AISLE_LIST_ERROR";

//add new
export const ADD_INGREDIENT = "ADD_INGREDIENT";
export const ADD_INGREDIENT_SUCCESS = "ADD_INGREDIENT_SUCCESS";
export const ADD_INGREDIENT_ERROR = "ADD_INGREDIENT_ERROR";

//add new
export const EDIT_INGREDIENT = "EDIT_INGREDIENT";
export const EDIT_INGREDIENT_SUCCESS = "EDIT_INGREDIENT_SUCCESS";
export const EDIT_INGREDIENT_ERROR = "EDIT_INGREDIENT_ERROR";

//delete
export const DELETE_INGREDIENT = "DELETE_INGREDIENT";
export const DELETE_INGREDIENT_SUCCESS = "DELETE_INGREDIENT_SUCCESS";
export const DELETE_INGREDIENT_ERROR = "DELETE_INGREDIENT_ERROR";

//setting data for edit
export const SET_INGREDIENT_LIST = "SET_INGREDIENT_LIST";

// set search of ingredients
export const SET_INGREDIENT_SEARCH = "SET_INGREDIENT_SEARCH";

// set sortField of ingredients
export const SET_INGREDIENTS_SORT_FIELD = "SET_INGREDIENTS_SORT_FIELD";

// set sortOrder of ingredients
export const SET_INGREDIENTS_SORT_ORDER = "SET_INGREDIENTS_SORT_ORDER";

// set page of ingredients
export const SET_INGREDIENTS_PAGE = "SET_INGREDIENTS_PAGE";
