import produce from "immer";
import {
  GET_CHALLENGE_LIST,
  GET_CHALLENGE_LIST_SUCCESS,
  GET_CHALLENGE_LIST_ERROR,
  GET_AISLE_LIST,
  GET_AISLE_LIST_SUCCESS,
  GET_AISLE_LIST_ERROR,
  ADD_CHALLENGE,
  ADD_CHALLENGE_SUCCESS,
  ADD_CHALLENGE_ERROR,
  SET_CHALLENGE_LIST,
  SET_CHALLENGE_STATUS,
  SET_CHALLENGE_SEARCH,
  SET_CHALLENGES_SORT_FIELD,
  SET_CHALLENGES_SORT_ORDER,
  SET_CHALLENGES_PAGE,
  GET_GOAL_LIST,
  GET_GOAL_LIST_SUCCESS,
  GET_GOAL_LIST_ERROR,
  GET_SINGLE_CHALLENGE,
  GET_SINGLE_CHALLENGE_SUCCESS,
  GET_SINGLE_CHALLENGE_ERROR,
  CHALLENGE_STATUS_UPDATE,
  CHALLENGE_STATUS_UPDATE_SUCCESS,
  CHALLENGE_STATUS_UPDATE_ERROR,
  CHALLENGE_FEATURE_UPDATE,
  CHALLENGE_FEATURE_UPDATE_SUCCESS,
  CHALLENGE_FEATURE_UPDATE_ERROR,
  GET_CHALLENGES_RECIPE_LIST,
  GET_CHALLENGES_RECIPE_LIST_SUCCESS,
  GET_CHALLENGES_RECIPE_LIST_ERROR,
  DELETE_CHALLENGE,
  DELETE_CHALLENGE_SUCCESS,
  DELETE_CHALLENGE_ERROR,
} from "./actionTypes";

const initialState = {
  allChallengesList: null,
  singleChallengeData: null,
  singleChallengeLoad: false,
  goalList: null,
  goalLoad: false,
  loading: false,
  error: "",
  totalCount: 0,
  totalPages: 0,
  limit: 5,
  page: 1,
  sortOrder: "",
  sortField: "",
  search: "",
  status: "",
  aisle_list: null,
  aisleLoad: false,
  aisleError: "",
  addChallengeLoad: false,
  challengeStatusList: null,
  challengeStatusUpdateLoad: false,
  challengeStatusUpdateList: null,
  challengeFeatureUpdateLoad: false,
  challengeFeatureUpdateList: null,
  challengesRecipeListLoading: false,
  challengesAllRecipeList: null,
  challengesRecipeTotalCount: 0,
  challengesRecipeTotalPages: 0,
  challengesRecipeLimit: 5,
  challengesRecipeCount: 0,
  deleteChallengeLoad: false,
};

const challengesReducer = produce((state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_GOAL_LIST:
      return {
        ...state,
        goalLoad: true,
      };
    case GET_GOAL_LIST_SUCCESS:
      return {
        ...state,
        goalList: payload,
        goalLoad: false,
      };

    case GET_GOAL_LIST_ERROR:
      return {
        ...state,
        goalLoad: false,
      };
    case GET_SINGLE_CHALLENGE:
      return {
        ...state,
        singleChallengeLoad: true,
      };
    case GET_SINGLE_CHALLENGE_SUCCESS:
      return {
        ...state,
        singleChallengeData: payload,
        singleChallengeLoad: false,
      };
    case GET_SINGLE_CHALLENGE_ERROR:
      return {
        ...state,
        singleChallengeLoad: false,
      };

    case GET_CHALLENGE_LIST:
      return {
        ...state,
        loading: true,
      };

    //set Challenges list
    case GET_CHALLENGE_LIST_SUCCESS:
      return {
        ...state,
        allChallengesList: payload.data,
        totalCount: payload.metadata.totalCount,
        totalPages: payload.metadata.totalPages,
        // page: payload.metadata.pages,
        limit: payload.metadata.limit,
        count: payload.metadata.count,
        challengeStatusList: payload.status,
        loading: false,
      };
    case GET_CHALLENGE_LIST_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    //Recipes list
    case GET_CHALLENGES_RECIPE_LIST:
      return {
        ...state,
        challengesRecipeListLoading: true,
      };
    case GET_CHALLENGES_RECIPE_LIST_SUCCESS:
      return {
        ...state,
        challengesAllRecipeList: payload.data,
        challengesRecipeTotalCount: payload.metadata.totalCount,
        challengesRecipeTotalPages: payload.metadata.totalPages,
        //page: payload.metadata.page,
        challengesRecipeLimit: payload.metadata.limit,
        challengesRecipeCount: payload.metadata.count,
        challengesRecipeListLoading: false,
      };
    case GET_CHALLENGES_RECIPE_LIST_ERROR:
      return {
        ...state,
        error: payload,
        challengesRecipeListLoading: false,
      };

    // set challenge status
    case SET_CHALLENGE_STATUS:
      return {
        ...state,
        status: payload,
      };

    //challenge status update
    case CHALLENGE_STATUS_UPDATE:
      return {
        ...state,
        challengeStatusUpdateLoad: true,
        loading: true,
      };

    case CHALLENGE_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        challengeStatusUpdateList: payload,
        challengeStatusUpdateLoad: false,
        loading: false,
      };
    case CHALLENGE_STATUS_UPDATE_ERROR:
      return {
        ...state,
        challengeStatusUpdateLoad: false,
        loading: false,
      };

    //challenge status update
    case CHALLENGE_FEATURE_UPDATE:
      return {
        ...state,
        challengeFeatureUpdateLoad: true,
        loading: true,
      };

    case CHALLENGE_FEATURE_UPDATE_SUCCESS:
      return {
        ...state,
        challengeFeatureUpdateList: payload,
        challengeFeatureUpdateLoad: false,
        loading: false,
      };
    case CHALLENGE_FEATURE_UPDATE_ERROR:
      return {
        ...state,
        challengeFeatureUpdateLoad: false,
        loading: false,
      };

    // set Challenges search
    case SET_CHALLENGE_SEARCH:
      return {
        ...state,
        search: payload,
      };
    // set Challenges sortField
    case SET_CHALLENGES_SORT_FIELD:
      return {
        ...state,
        sortField: payload,
      };

    // set Challenges sortOrder
    case SET_CHALLENGES_SORT_ORDER:
      return {
        ...state,
        sortOrder: payload,
      };
    // set Challenges Page
    case SET_CHALLENGES_PAGE:
      return {
        ...state,
        page: payload,
      };
    case GET_AISLE_LIST:
      return {
        ...state,
        aisleLoad: true,
      };
    case GET_AISLE_LIST_SUCCESS:
      return {
        ...state,
        aisle_list: payload,
        aisleLoad: false,
      };
    case GET_AISLE_LIST_ERROR:
      return {
        ...state,
        aisleLoad: false,
        aisleError: payload,
      };
    case ADD_CHALLENGE:
      return {
        ...state,
        addChallengeLoad: true,
      };
    case ADD_CHALLENGE_SUCCESS:
      return {
        ...state,
        addChallengeLoad: false,
      };
    case ADD_CHALLENGE_ERROR:
      return {
        ...state,
        addChallengeLoad: false,
      };

    case SET_CHALLENGE_LIST:
      return {
        ...state,
        allChallengesList: payload,
      };

    //delete
    case DELETE_CHALLENGE:
      return {
        ...state,
        deleteChallengeLoad: true,
      };
    case DELETE_CHALLENGE_SUCCESS:
      return {
        ...state,
        deleteChallengeLoad: false,
      };
    case DELETE_CHALLENGE_ERROR:
      return {
        ...state,
        deleteChallengeLoad: false,
      };
    default:
      return state;
  }
});

export default challengesReducer;
