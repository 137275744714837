import {
  GET_FAQS,
  GET_FAQS_SUCCESS,
  GET_FAQS_ERROR,
  ADD_FAQ,
  ADD_FAQ_SUCCESS,
  ADD_FAQ_ERROR,
  EDIT_FAQ,
  EDIT_FAQ_SUCCESS,
  EDIT_FAQ_ERROR,
  SET_FAQS_LIST,
} from "./actionTypes";

const initialState = {
  loading: false,
  faqs: [],
  totalSize: 0,
  loadingAddEditFaq: false,
};

const FaqReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    //fetch all faq
    case GET_FAQS:
      return {
        ...state,
        loading: true,
      };
    case GET_FAQS_SUCCESS:
      return {
        ...state,
        faqs: payload,
        totalSize: payload.count,
        loading: false,
      };
    case GET_FAQS_ERROR:
      return {
        ...state,
        loading: false,
      };
    //   // Add faq
    case ADD_FAQ:
      return {
        ...state,
        loadingAddEditFaq: true,
      };
    case ADD_FAQ_SUCCESS:
      return {
        ...state,
        loadingAddEditFaq: false,
      };
    case ADD_FAQ_ERROR:
      return {
        ...state,
        loadingAddEditFaq: false,
      };
    // Edit faq
    case EDIT_FAQ:
      return {
        ...state,
        loadingAddEditFaq: true,
      };
    case EDIT_FAQ_SUCCESS:
      return {
        ...state,
        loadingAddEditFaq: false,
      };
    case EDIT_FAQ_ERROR:
      return {
        ...state,
        loadingAddEditFaq: false,
      };
    case SET_FAQS_LIST:
      return {
        ...state,
        faqs: payload,
      };

    default:
      return state;
  }
};

export default FaqReducer;
