// Notification listing
export const GET_NOTIFICATION_LIST = "GET_NOTIFICATION_LIST";
export const GET_NOTIFICATION_LIST_SUCCESS = "GET_NOTIFICATION_LIST_SUCCESS";
export const GET_NOTIFICATION_LIST_ERROR = "GET_NOTIFICATION_LIST_ERROR";

// aisle listing
// export const GET_AISLE_LIST = "GET_AISLE_LIST";
// export const GET_AISLE_LIST_SUCCESS = "GET_AISLE_LIST_SUCCESS";
// export const GET_AISLE_LIST_ERROR = "GET_AISLE_LIST_ERROR";

//add new
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const ADD_NOTIFICATION_SUCCESS = "ADD_NOTIFICATION_SUCCESS";
export const ADD_NOTIFICATION_ERROR = "ADD_NOTIFICATION_ERROR";

//add new
export const EDIT_NOTIFICATION = "ADD_NOTIFICATION";
export const EDIT_NOTIFICATION_SUCCESS = "ADD_NOTIFICATION_SUCCESS";
export const EDIT_NOTIFICATION_ERROR = "ADD_NOTIFICATION_ERROR";

//delete
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS";
export const DELETE_NOTIFICATION_ERROR = "DELETE_NOTIFICATION_ERROR";

//Notification staus
export const SET_NOTIFICATION_STATUS = "SET_NOTIFICATION_STATUS";

//Notification data for edit
export const SET_NOTIFICATION_LIST = "SET_NOTIFICATION_LIST";

// set search of Notification
export const SET_NOTIFICATION_SEARCH = "SET_NOTIFICATION_SEARCH";

// set sortField of Notification
export const SET_NOTIFICATIONS_SORT_FIELD = "SET_NOTIFICATIONS_SORT_FIELD";

// set sortOrder of Notification
export const SET_NOTIFICATIONS_SORT_ORDER = "SET_NOTIFICATIONS_SORT_ORDER";

// set page of Notification
export const SET_NOTIFICATIONS_PAGE = "SET_NOTIFICATIONS_PAGE";
