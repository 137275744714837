// videos listing
export const GET_VIDEO_LIST = "GET_VIDEO_LIST";
export const GET_VIDEO_LIST_SUCCESS = "GET_VIDEO_LIST_SUCCESS";
export const GET_VIDEO_LIST_ERROR = "GET_VIDEO_LIST_ERROR";

// aisle listing
export const GET_AISLE_LIST = "GET_AISLE_LIST";
export const GET_AISLE_LIST_SUCCESS = "GET_AISLE_LIST_SUCCESS";
export const GET_AISLE_LIST_ERROR = "GET_AISLE_LIST_ERROR";

//add new
export const ADD_VIDEO = "ADD_VIDEO";
export const ADD_VIDEO_SUCCESS = "ADD_VIDEO_SUCCESS";
export const ADD_VIDEO_ERROR = "ADD_VIDEO_ERROR";

//add new
export const EDIT_VIDEO = "ADD_VIDEO";
export const EDIT_VIDEO_SUCCESS = "ADD_VIDEO_SUCCESS";
export const EDIT_VIDEO_ERROR = "ADD_VIDEO_ERROR";

//delete
export const DELETE_VIDEO = "DELETE_VIDEO";
export const DELETE_VIDEO_SUCCESS = "DELETE_VIDEO_SUCCESS";
export const DELETE_VIDEO_ERROR = "DELETE_VIDEO_ERROR";

//setting data for edit
export const SET_VIDEO_LIST = "SET_VIDEO_LIST";

// set search of videos
export const SET_VIDEO_SEARCH = "SET_VIDEO_SEARCH";

// set sortField of videos
export const SET_VIDEOS_SORT_FIELD = "SET_VIDEOS_SORT_FIELD";

// set sortOrder of videos
export const SET_VIDEOS_SORT_ORDER = "SET_VIDEOS_SORT_ORDER";

// set page of videos
export const SET_VIDEOS_PAGE = "SET_VIDEOS_PAGE";
