import {
  GET_FORGOT_PASSWORD,
  GET_FORGOT_PASSWORD_SUCCESS,
  GET_FORGOT_PASSWORD_ERROR,
} from "./actionTypes";
const initialState = {
  loading: false,
  userForgot: null,
};

const ForgotPasswordReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_FORGOT_PASSWORD:
      return {
        ...state,
        loading: true,
      };
    case GET_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        userForgot: payload,
        loading: false,
      };
    case GET_FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default ForgotPasswordReducer;
