import produce from "immer";
import {
  GET_SUBSCRIBER_LIST,
  GET_SUBSCRIBER_LIST_SUCCESS,
  GET_SUBSCRIBER_LIST_ERROR,
} from "./actionTypes";

const initialState = {
  subscriberData: null,
  total_accounts: 0,
  total_subscibers: 0,
  loading: false,
  error: "",
};

const subscriberReducer = produce((state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_SUBSCRIBER_LIST:
      return {
        ...state,
        loading: true,
      };

    //set user list
    case GET_SUBSCRIBER_LIST_SUCCESS:
      return {
        ...state,
        subscriberData: payload?.data[0].subscriberDetails,
        total_accounts: payload?.data[0].total_accounts,
        total_subscibers: payload?.data[0].total_subscibers,
        loading: false,
      };
    case GET_SUBSCRIBER_LIST_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    default:
      return state;
  }
});

export default subscriberReducer;
