//login admin
export const LOGIN_URL = "/login";
export const LOGOUT_URL = "/logout";
export const FORGOT_PASSWORD = "/forget-password";
export const RESET_PASSWORD_WEB = "/reset-password";
export const RESET_PASSWORD = "/resetUserPassword";
export const CHECK_RESET_PASSWORD_TOKEN = "/checkResetPasswordToken";

//ingredient
export const GET_INGREDIENT_LIST_URL = "/ingredient-list";
export const ADD_INGREDIENTS_URL = "/add-ingredient";
export const EDIT_INGREDIENTS_URL = "/edit-ingredient";
export const DELETE_INGREDIENTS_URL = "/delete-ingredient";

//tags

export const GET_TAGS_LIST_URL = "/listTag";
export const ADD_TAGS_URL = "/addTag";
export const EDIT_TAGS_URL = "/editTag";
export const DELETE_TAGS_URL = "/deleteTag";

//aisle list
export const GET_AISLE_LIST_URL = "/get-aisle";

//image upload

export const UPLOAD_URL = "/upload";

//recipe
export const GET_RECIPE_LIST_URL = "/recipe-list";
export const GET_CATEGORY_LIST_URL = "/category-list";
export const GET_RECIPE_URL = "/get-recipe";
export const ADD_RECIPE_URL = "/add-recipe";
export const EDIT_RECIPE_URL = "/edit-recipe";
export const DELETE_RECIPE_URL = "/delete-recipe";
export const TOGGLE_STATUS_RECIPE_URL = "/toggle-recipe-status";
export const GET_STATUS_RECIPE_URL = "/recipe-status-list";
export const GET_EXPORT_RECIPE = "/export-recipe";
export const GET_RECIPE_TAG_LIST = "/listTagForRecipe";

//challenges

export const GOAL_LIST_URL = "/goal-list";
export const GET_CHALLENGE_URL = "/get-challenge";
export const CHALLENGES_LIST_URL = "/challenges-list";
export const ADD_CHALLENGES_URL = "/add-challenge";
export const EDIT_CHALLENGES_URL = "/edit-challenge";
export const DELETE_CHALLENGES_URL = "/delete-challenge";
export const DELETE_CHALLENGES_RECIPE_URL = "/delete-challenge-recipe";
export const TOGGLE_STATUS_CHALLENGE_URL = "/toggle-status-challenge";
export const FEATURE_CHALLENGE_URL = "/feature-challenge";
export const EDIT_CHALLENGE_ORDER_URL = "/editChallengeOrder";
export const EDIT_GOAL_ORDER_URL = "/editGoalOrder";

// Products

export const PRODUCTS_LIST_URL = "/listProduct";
export const GET_PRODUCT_URL = "/get-challenge";
export const ADD_PRODUCT_URL = "/addProduct";
export const EDIT_PRODUCT_URL = "/editProduct";
export const DELETE_PRODUCT_URL = "/deleteProduct";
export const DRAG_DROP_PRODUCT = "/editProductOrder";

// Video
export const GET_VIDEO_LIST_URL = "/video-list";
export const ADD_VIDEOS_URL = "/add-video";
export const EDIT_VIDEOS_URL = "/edit-video";
export const DELETE_VIDEOS_URL = "/delete-video";

// Video
export const GET_PLAYLIST_URL = "/list-playlist";
export const ADD_PLAYLIST_URL = "/add-playlist";
export const EDIT_PLAYLIST_URL = "/edit-playlist";
export const DELETE_PLAYLIST_URL = "/delete-playlist";

//Notification
export const GET_NOTIFICATION_LIST_URL = "/list-notification";
export const ADD_NOTIFICATIONS_URL = "/add-notification";
export const EDIT_NOTIFICATIONS_URL = "";
export const DELETE_NOTIFICATIONS_URL = "/delete-notification";

//Users
export const GET_USER_LIST_URL = "/userDashboardData";
export const DELETE_USERS_URL = "/deleteUserAccount";
export const EDIT_USER_LIST_URL = "/editUserSubscription";

//FAQS
export const LIST_ALL_FAQ = "/listFaq";
export const ADD_FAQS = "/addFaq";
export const EDIT_FAQS = "/editFaq";
export const DELETE_FAQ = "/deletefaq";
export const DRAG_DROP_FAQ = "/editFaqOrder";

//Subscriber

export const GET_SUBSCRIBER_DATA = "/getSubscriberData";

//Aisle
export const ADD_AISLE = "/addAisle";
export const EDIT_AISLE = "/editAisle";
export const LIST_AISLE = "/listAisle";
export const DELETE_AISLE = "/deleteAisle";

// Category
export const ADD_RECIPE_CATEGORY = "/addRecipeCategories";
export const EDIT_RECIPE_CATEGORY = "/editRecipeCategory";
export const LIST_RECIPE_CATEGORY = "/listRecipeCategories";
export const DELETE_RECIPE_CATEGORY = "/deleteRecipeCategories";

// Goal
export const ADD_GOAL = "/addGoal";
export const EDIT_GOAL = "/editGoal";
export const LIST_GOAL = "/listGoal";
export const DELETE_GOAL = "/deleteGoal";
